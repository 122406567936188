import { createWebHistory, createRouter } from "vue-router"
import ProjectList from "@/views/ProjectList.vue";
import AboutPage from "@/views/AboutPage.vue";
import WikiWomen from "@/views/Wiki-Women.vue";
import MUTTBackend from "@/views/MUTT-Backend.vue";
import MUTTAdmin from "@/views/MUTT-Admin.vue";
import Capstone from "@/views/Capstone.vue";
import ResumePage from "@/views/ResumePage.vue";
import STRIDeS from "@/views/STRIDeS.vue";
import RemnantTrust from "@/views/RemnantTrust.vue";

const routes = [
    {
        path: "/Projects",
        name: "ProjectList",
        component: ProjectList,
    },
    {
        path: "/About",
        name: "AboutPage",
        component: AboutPage,
    },
    {
        path: "/",
        redirect: "/Projects"
    },
    {
        path: "/MUTT-Backend",
        name: "MUTT-Backend",
        component: MUTTBackend,
    },
    {
        path: "/MUTT-Admin",
        name: "MUTT-Admin",
        component: MUTTAdmin,
    },
    {
        path: "/Capstone",
        name: "Capstone",
        component: Capstone,
    },
    {
        path: "/Resume",
        name: "Resume",
        component: ResumePage,
    },
    {
        path: "/Wiki-Women",
        name: "Wiki-Women",
        component: WikiWomen,
    },
    {
        path: "/STRIDeS",
        name: "STRIDeS",
        component: STRIDeS,
    },
    {
        path: "/RemnantTrust",
        name: "RemnantTrust",
        component: RemnantTrust,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router