<template>
  <header class="header">
      <router-link to="/" class="page-logo">
          <img :src="require('/src/assets/logo.png')">
      </router-link>
      <div class="header-right">
      <router-link to="/Projects" class="side-links">Projects</router-link>
      <router-link to="/About" class="side-links">About</router-link>
      <router-link to="/Resume" class="side-links">Résumé</router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader.vue"
}
</script>

<style scoped>
.header{
  color: #E0D8CC;
  top: 0;
  width: 100%;
  z-index: 999;
  padding-top: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #E0D8CC;
  text-decoration: none;
}
.page-title{
  font-size: 60px;
  line-height: normal;
  text-align: left;
  padding: 15px 25px 10px;
  margin-left: 25px;
  text-decoration: none;
  color: #E0D8CC;

}
.page-title:hover{
  text-shadow: 6px 6px 8px #000000;
  cursor: pointer;
}
.side-links{
  line-height: normal;
  margin-bottom: 10%;
  min-height: 26px;
  width: 103px;
  font-size: 35px;
  padding: 15px 15px 0;
  text-decoration: none;
  color: #E0D8CC;

}
.side-links:hover{
  text-shadow: 3px 3px 8px #000000;
  text-decoration: underline;
}

.header-right{
  float: right;
  padding: 18px 18px 0;
  margin-right: 25px;
}

.page-logo{
    display: flex;
    padding: 15px;
    margin-left: 35px;
}

@media only screen and (max-width: 800px) {
   .side-links{
      font-size: 20px !important;
       padding: 5px;
   }
    .header-right{
        padding: 5px !important;
        margin: 0;
    }

    .page-logo{
        display: flex;
        padding: 10px;
        margin-left: 22px;
    }
    img{
        max-height: 7vh;
    }
}
</style>