<template>
    <head>
        <title>Capstone</title>
    </head>
    <h1>Capstone</h1>
  <hr class="border">
  <div class="image-container">
    <img :src="require('/src/assets/eFinPlan-planning.png')" alt="A webpage that shows a financial planning quiz">
    <img :src="require('/src/assets/eFinPlanRegister.png')" alt="A webpage that shows a sign up/register page">
  </div>
  <hr class="border">
  <div class="tech-container">
    <h2 class="techstack">Tech Stack</h2>
    <hr class="border">
    <p>Vue.js, HTML/CSS, .NET, and Visual Studio Code.</p>
  </div>
  <div>
    <h2 class="techstack">Overview</h2>
    <hr class="border">
    <p>
      For my Capstone project, I had the opportunity to work with a team of developers to create a financial planning platform for a local company. Our client wanted a web application that would allow for financial advisor’s and investors to create financial plans, schedule appointments with one another, and for advisors to post content for investors to view.
    </p>
    <p>
      Our goal for this project was to create a platform that fulfilled not only the client’s needs, but the investor’s and advisor’s needs as well. The platform would need to have all the necessary functionality to manage financial planning effectively. We aimed to create a platform that would be easy to understand and use, with clear navigation and organization.
    </p>
    <p>
      I played a key role in the design process for this project, as I was tasked with creating the Figma prototype. I worked closely with my team and our client to ensure that the design met their needs and requirements. It was essential to create a design that would be intuitive and easy to use.
    </p>
    <p>
      We used Vue.js to create the front end, and .NET to create the backend. I started by creating the initial pages and styling them to match the Figma. I established the initial routes and made a functional header. With each iteration, I was tasked with creating and styling each new page. I worked closely with my team members to create pull requests and avoid any issues with the git process. For our final iteration, I utilized the Google Lighthouse tool to ensure accessibility. I then implemented Aria tags to each page to ensure compatibility with screen readers. Ultimately, I was able to achieve an overall accessibility rating of 100%
    </p>
    <p>
      All in all, Capstone has given me hands-on experience with the development life cycle. It has introduced me to a new framework and given me experience in the design process.
    </p>
  </div>

  <div class="arrow-container">
      <router-link to="/Projects">
        <img :src="require('/src/assets/Arrow2.png')" alt="an arrow">
      </router-link>
    <h1 class="back">Back to projects</h1>
  </div>
</template>

<script>
export default {
    title: 'Capstone Project',
    name: "CapstoneProject"
}
</script>

<style scoped>
.arrow-container{
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}
.back{
  text-decoration: none;
  color: #E0D8CC;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
}
img{
  max-height: 30vh;
}
.image-container{
  display: flex;
  justify-content: space-evenly;
  padding: 30px;
}
h1{
  text-align: center;
  text-decoration: none;
  font-size: 65px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 75%;
  display: flex;
  justify-content: center;
}
.techstack{
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding-top: 30px;
  padding-left: 13%;
}
p{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 52px;
  color: #E0D8CC;
  padding-left: 13%;
  padding-right: 10%;
  margin-top: 0;
}
</style>