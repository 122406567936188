<template>
    <head>
      <title>MUTT Board Backend</title>
    </head>
    <h1>MUTT Board Backend</h1>
  <hr class="border">
  <div class="tech-container">
    <h2 class="techstack">Tech Stack</h2>
    <hr class="border">
    <p>Adonis, PHP Storm, and Sequel Ace.</p>
  </div>
  <div>
    <h2 class="techstack">Overview</h2>
    <hr class="border">
    <p>
      The MUTT Board is a large touchscreen TV located outside of the Digital Corps office. The board serves as a platform for displaying information and experiences for employees and students of Ball State. As a part of this project, I was tasked with using Adonis to create a backend for the launcher, which would allow us to easily manage and update content displayed on the board.
    </p>
    <p>
      The primary goal for this project was to create a backend that would allow us to add, edit, and remove content from the MUTT Board. We identified a set of key fields that the backend needed to include, such as Item Title, URL, thumbnail image, item type, and more, in order to ensure that we could manage and organize the content effectively.
    </p>
    <p>
      I used Adonis to create the backend for the launcher. Each experience for the board needed these fields: Item title, URL, thumbnail, item type (website, video, or image), item color, active flag, item duration, and home button position. This was somewhat of a challenge for me as I was brand new to Adonis. I spent a lot of time debugging and testing the database in Sequel Ace.
    </p>
    <p>
      After the backend was completed, my coworker was able to use my backend to build the front end for the board. Then, I was tasked with creating an admin panel for the board so that admins could add the actual content that was to be displayed on the board.
    </p>
    <p>
      Overall, this project tested my skills and provided a great learning experience for me. I am happy with the result and hope to apply what I have learned to future project.
    </p>
  </div>
  <div class="arrow-container">
    <router-link to="/Projects">
      <img :src="require('/src/assets/Arrow2.png')" >
    </router-link>
    <h1 class="back">Back to projects</h1>
  </div>
</template>

<script>
export default {
    title: 'MUTT Board Backend Project',
    name: "MUTT-Backend"
}
</script>

<style scoped>
.arrow-container{
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}
.back{
  text-decoration: none;
  color: #E0D8CC;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
}
h1{
  text-align: center;
  font-size: 65px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 75%;
  display: flex;
  justify-content: center;
}
.techstack{
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding-top: 30px;
  padding-left: 13%;
}
p{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 52px;
  color: #E0D8CC;
  padding-left: 13%;
  padding-right: 10%;
  margin-top: 0;
}
</style>