<template>
  <div class="footer-container">
  <hr class="border">
    <div class="link-container">
      <a href="https://github.com/desnelson" target=”_blank”><img :src="require('/src/assets/github-mark-white.png')" class="image"></a>
      <a href="https://www.linkedin.com/in/djnelsonln/" target=”_blank”><img :src="require('/src/assets/linkedin.png')" class="image1"></a>
            <a href="mailto:djnelson.development@gmail.com" target=”_blank”><img :src="require('/src/assets/emaillogo.png')" class="image2"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter.vue"
}
</script>

<style scoped>
.footer-container{
  /*
  border-top: #E0D8CC solid 2px;
  */
}
.link-container{
  display: flex;
  justify-content: space-evenly;
  padding-right: 40px;
  padding-left: 40px;
}
.link-container a{
  height: 5%;
  width: 5%;
  padding: 35px;
}
.image1{
  height: 100%;
  width: 100%;
  filter: invert(100%);
}
.image{
  height: 100%;
  width: 100%;
}
.image2{
  height: 140%;
  width: 140%;
  
}

.border{
  border-bottom: #E0D8CC solid 2px;
  width: 85%;
  display: flex;
  justify-content: center;
}

</style>