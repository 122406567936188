<template>
    <head>
        <title>Projects</title>
    </head>
  <div class="full-container">
    <h1 class="projects">Projects</h1>
    <hr class="border">
  </div>
  <div class="project-container">
        <div class="double-container">
            <router-link to="/STRIDeS">
                <p class="project-title">STRIDeS Website</p>
                <hr class="underline">
                <p class="project-desc">Using Wordpress, I made a static “coming soon” page, a header, and footer for our client.  </p>
                <div class="arrow"><router-link to="/STRIDeSLanding"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
                <hr class="underline">
            </router-link>
        </div>
        <br>
      <div class="double-container">
          <router-link to="/RemnantTrust">
              <p class="project-title">Remnant Trust Puzzles</p>
              <hr class="underline">
              <p class="project-desc"> As a development apprentice for the Digital Corps, I collaborated with the EMDD team to create the 'Matching' and 'Four Pics One Word' puzzles using React TypeScript for an interactive scavenger hunt.</p>
              <div class="arrow"><router-link to="/RemnantTrust"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
              <hr class="underline">
          </router-link>
      </div>
      <br>
        <div class="double-container">
            <router-link to="/Capstone">
                <p class="project-title">Capstone</p>
                <hr class="underline">
                <p class="project-desc"> In this year long course, I worked with a team of other students to build a product for a client. </p>
                <div class="arrow"><router-link to="/Capstone"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
                <hr class="underline">
            </router-link>
        </div>
        <br>
        <div class="double-container">
            <router-link to="/MUTT-Backend">
                <p class="project-title">MUTT Backend</p>
                <hr class="underline">
                <p class="project-desc">I created the backend for the MUTT Board using Adonis.</p>
                <div class="arrow"><router-link to="/MUTT-Backend"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
                <hr class="underline">
            </router-link>
        </div>
        <br>
        <div class="double-container">
            <router-link to="/MUTT-Admin">
                <p class="project-title">MUTT Admin Panel</p>
                <hr class="underline">
                <p class="project-desc"> I made an admin panel which allowed for admins of the MUTT Board to easily add, edit, and delete content.</p>
                <div class="arrow"><router-link to="/MUTT-Admin"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
                <hr class="underline">
            </router-link>
        </div>
        <br>
      <div class="double-container">
        <router-link to="/Wiki-Women">
          <p class="project-title">Wiki-Women</p>
           <hr class="underline">
           <p class="project-desc">With other classmates, I created a Java application that pulls data from the Wiki-Data API to create profiles for women in STEM.
           </p>
        </router-link>
      <div class="arrow"><router-link to="/Wiki-Women"><img :src="require('/src/assets/Arrow.png')"></router-link></div>
      <hr class="underline">
    </div>
  </div>
    <br>
</template>

<script>
export default {
    title: 'Projects',
    name: "ProjectList.vue"
}
</script>

<style scoped>
br {
  display: block;
  content: "";
  margin-top: 75px;
}
.projects{
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #E0D8CC;
  text-decoration: none;
  margin-bottom: 5px;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 80%;
  display: flex;
  justify-content: center;
}
.underline{
  border-bottom: #B7A698 solid 2px;
  width: 90%;
}
.project-title{
  font-size: 45px;
  margin: 0;
  padding-left: 60px;
  text-decoration: none;
  color: #E0D8CC;
}
.project-desc{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 0;
  color: #E0D8CC;
  text-decoration: none;
}
.project-container{
  width: 88%;
  margin: 0 auto;
  padding-top: 30px;
  text-decoration: none;
  color: #E0D8CC;
}
.double-container{
  padding: 15px;
  border-right: #E0D8CC solid 2px;
  border-left: #E0D8CC solid 2px;
  color: #E0D8CC;
  text-decoration: none;
}
.arrow{
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
  padding-right: 45px;
  padding-top: 10px;
}
a{
  color: #E0D8CC;
  text-decoration: none;
}
</style>