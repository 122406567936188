<template>
    <head>
        <title>Remnant Trust Puzzles</title>
    </head>
    <h1>Remnant Trust Puzzles</h1>
    <hr class="border">
    <div class="center-image">
    <div class="image-container">
        <div class="img-gif">
        <img :src="require('/src/assets/matching.gif')" >
        <img :src="require('/src/assets/4pics1word.gif')" >
        </div>
    </div>
    </div>
    <hr class="border">
    <div class="tech-container">
        <h2 class="techstack">Tech Stack</h2>
        <hr class="border">
        <p>React.tsx and Phpstorm</p>
    </div>
    <div>
        <h2 class="techstack">Overview</h2>
        <hr class="border">
        <p>
            The Remnant Trust is an educational foundation that has a collection of first edition books and works. The Ball State EMDD team partnered with the Remnant Trust as well as the Digital Corps in order to update their website and create an interactive scavenger hunt
        </p>
        <p>
            The interactive scavenger hunt consisted of a series of puzzles. As a team, we opted to divy up the puzzles. I was tasked with the completion of the ‘Matching’ puzzle and the ‘Four Pics One Word’ puzzle. Once all of the puzzles were completed, they would be connected to create each scavenger hunt experience.
        </p>
        <p>
            Using React typescript, I was able to develop my assigned puzzles. I used CSS to match them to the Figmas that were given to me by the EMDD team. I utilized responsive web design so that the site was styled for both desktop and mobile.
        </p>
    </div>

    <div class="arrow-container">
        <router-link to="/Projects">
            <img :src="require('/src/assets/Arrow2.png')">
        </router-link>
        <h1 class="back">Back to projects</h1>
    </div>
</template>

<script>
export default {
    title: 'Remnant Trust Puzzles',
    name: "RemnantTrust"
}
</script>

<style scoped>
.arrow-container{
    padding: 20px;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
}
.back{
    text-decoration: none;
    color: #E0D8CC;
    font-size: 20px;
    margin: 0;
    padding-left: 10px;
}
.img-gif img{
    width: 30vw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.image-container{
    padding: 30px;
}
h1{
    text-align: center;
    text-decoration: none;
    font-size: 65px;
    margin-bottom: 0;
    font-family: 'Mate SC';
    font-style: normal;
    font-weight: 400;
    color: #E0D8CC;
}
.border{
    border-bottom: #E0D8CC solid 2px;
    width: 75%;
    display: flex;
    justify-content: center;
}
.techstack{
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    margin: 0;
    padding-top: 30px;
    padding-left: 13%;
}
p{
    font-family: 'Anek Bangla';
    font-style: normal;
    font-weight: 200;
    font-size: 30px;
    line-height: 52px;
    color: #E0D8CC;
    padding-left: 13%;
    padding-right: 10%;
    margin-top: 0;
}
.center-image{
    display: flex;
    justify-content: center;
}
</style>