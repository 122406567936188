<template>
    <head>
        <title>About Me</title>
    </head>
    <div @click="flipImage" class="flip-container" :class="{ 'is-flipped': flipped }">
        <div class="flipper">
            <div class="front">
                <img :src="image1" alt="front" />
            </div>
            <div class="back">
                <img :src="image2" alt="back" />
            </div>
        </div>
    </div>
  <h1>
    Hello!
  </h1>
  <div class="page-container">
    <hr class="border">
<div class="about-container">
  <div class="about-section">
    <div class="info-container">
      <p>  My name is Desirée and I am a full stack developer. </p>
        <p> During my time at the Digital Corps, I had the opportunity to collaborate on projects with 6 different teams, meeting project deadlines and ensuring client satisfaction. I was able to develop a strong foundation in front end development, utilizing HTML, CSS, React, and Wordpress. I also gained backend experience with Adonis.</p>
          <p>I have experience with Javascript, Typescript, Python, Java, React, Vue, Adonis, HTML, CSS, Wordpress, Git, GIthub, and Bitbucket. I am eager to learn many different tech stacks and languages, and I am always looking for ways to expand my skill set. </p>
           <p> In addition to being a developer, I am a proud rat enthusiast. I used to have 4 rats as pets and they were my pride and joy. Although I no longer have them, I cherish the memories I have with them and do what I can to eliminate the bad stereotypes of rats. Currently, I have 2 cats, named Mushroom and Clover.</p>
              <p>Thank you for visiting my portfolio site! For any inquiries, I can be contacted <a href="mailto:djnelson.development@gmail.com">by email.</a> I look forward to connecting with you soon!</p>   
              
               </div>
  </div>

</div>
  </div>
</template>

<script>
import image1 from '@/assets/moon.png';
import image2 from '@/assets/newheadshot.png';
export default {
    title: 'About',
    name: "AboutPage.vue",
    data() {
        return {
            flipped: false,
            image1: image1,
            image2: image2,
        }
    },
    methods: {
        flipImage() {
            this.flipped = !this.flipped;
        }
    }
}
</script>

<style scoped>
.page-container{
  padding-top: 20px;
}
h1{
  text-align: center;
  font-size: 40px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.about-container{
  display: flex;
  margin: auto;
  max-width: 80%;
  padding-bottom: 10px;
}
.about-section{
  color: #440D0F;
  display: flex;
  flex-direction: column;
}
.info-container{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  color: #E0D8CC;
}



.border{
  border-bottom: #E0D8CC solid 2px;
  width: 85%;
  display: flex;
  justify-content: center;
}

.flipper img{
    height: auto;
    width: 250px;
    cursor: pointer;
}

.flip-container {
    perspective: 1000px;
    position: relative;
    display: flex;
    padding: 20px;
    margin: 22px auto auto;
    justify-content: center;
    width: 250px;
    height: 250px;
}

.flip-container.is-flipped .flipper {
    transform: rotateY(180deg);
}

.flip-container .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 100%;
}

.flip-container .flipper .front,
.flip-container .flipper .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flip-container .flipper .front {
    z-index: 2;
    transform: rotateY(0deg);
}

.flip-container .flipper .back {
    transform: rotateY(180deg);
}
@media only screen and (max-width: 800px) {
    .about-section{
        padding-left: 0;
    }
}

a {
      color: #E0D8CC;
}
</style>