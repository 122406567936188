<template>
    <head>
        <title>MUTT Board Admin Panel</title>
    </head>
    <h1>MUTT Board Admin Panel</h1>
  <hr class="border">
  <div class="image-container"><img :src="require('/src/assets/adminpanel.png')"></div>
  <hr class="border">

  <div class="tech-container">
    <h2 class="techstack">Tech Stack</h2>
    <hr class="border">
    <p>React.js, and HTML/CSS.</p>
  </div>
  <div>
    <h2 class="techstack">Overview</h2>
    <hr class="border">
    <p>
      The MUTT Board is a large touchscreen TV outside the Digital Corps office that displays information and experiences for employees and students of Ball State. Building off of the backend I created, I was tasked with creating an admin panel so that admins could add experiences to the board.
    </p>
    <p>
      The main goal of the admin panel was to create an efficient and easy way for admins (Digital Corps employees) to edit, add, and delete experiences from the launcher. The admin panel was meant to be a way for people with no development background to maintain the MUTT Board.
    </p>
    <p>
      With the help of the design team, I was able to get a Figma of how the admin panel would look. I used this to create a HTML page that had all the required fields. I then used React to add the functionality needed. This proved to be a challenge as I ended up finding bugs within the backend. It also allowed me to see things that needed to be added for a better user experience. One feature I added was a way to ‘spotlight’ an experience. This added an extra field in the backend that when marked as active would only show experiences with the active field. This feature was necessary to add so that admins would not have to go to each active experience and set them to inactive, and instead they would just have to spotlight one. Once functional, I was able to style the page with CSS to look like the provided Figma.
    </p>
    <p>
      All in all, creating the admin panel strengthened my React skills and problem solving skills. The panel has made an impact on how experiences are managed and displayed for admins.
    </p>
  </div>
  <div class="arrow-container">
    <router-link to="/Projects">
      <img :src="require('/src/assets/Arrow2.png')" >
    </router-link>
    <h1 class="back">Back to projects</h1>
  </div>
</template>

<script>
export default {
    title: 'MUTT Admin Project',
    name: "MUTT-Admin"
}
</script>

<style scoped>
.arrow-container{
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}
.back{
  text-decoration: none;
  color: #E0D8CC;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
}
img{
  max-height: 55vh;
}
.image-container{
  display: flex;
  justify-content: center;
  padding: 30px;
}
h1{
  text-align: center;
  font-size: 65px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 75%;
  display: flex;
  justify-content: center;
}
.techstack{
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding-top: 30px;
  padding-left: 13%;
}
p{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 52px;
  color: #E0D8CC;
  padding-left: 13%;
  padding-right: 10%;
  margin-top: 0;
}
</style>