<template>
<head>
    <title>Wiki-Women</title>
</head>
    <h1>Wiki-Women</h1>
  <hr class="border">
  <div class="image-container">
    <img :src="require('/src/assets/Wiki-Women.png')">
  </div>
  <hr class="border">
  <div class="tech-container">
    <h2 class="techstack">Tech Stack</h2>
    <hr class="border">
    <p>Java and IntelliJ </p>
  </div>
  <div>
    <h2 class="techstack">Overview</h2>
    <hr class="border">
    <p>
      In CS 222, ‘Advanced Programming’, I had the opportunity to create Wiki-Women. Wiki-Women is a Java application that utilizes the WikiData API to retrieve information about women in STEM fields from their Wikipedia page. My team designed and developed this application in order to showcase the achievements and contributions of women in STEM.
    </p>
    <p>
      We developed Wiki-Women using Java and utilized the WikiData API. One of the key challenges that we faced was working with the API’s complex data structure, which required careful parsing and organization.
    </p>
    <p>
      Our design process involved creating a visual mockup as well as a Figma to help us visualize the application’s interface and user flow. We aimed to create a clean and intuitive design that would be simple for users of any age to use. We considered our target audience and their needs/uses of the application.
    </p>
    <p>
      Once we finalized our design, created user stories, and planned the requirements for each iteration, we began coding the application. We spent a significant amount of time testing and debugging the application to ensure it was functioning correctly and efficiently.
    </p>
    <p>
      Overall, I am proud of what my team accomplished with Wiki-Women. We made a meaningful application to impact the promotion of gender equity in STEM fields. This project aided in the development of my skills, specifically team work and agile software development. As I continue to pursue a career in technology, I look forward to building on the knowledge and experience gained in this project.
    </p>
  </div>
  <div class="arrow-container">
    <router-link to="/Projects">
      <img :src="require('/src/assets/Arrow2.png')" >
    </router-link>
    <h1 class="back">Back to projects</h1>
  </div>
</template>

<script>
export default {
    title: 'Wiki-Women Project',
    name: "Wiki-Women"
}
</script>

<style scoped>
.arrow-container{
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}
.back{
  text-decoration: none;
  color: #E0D8CC;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
}
img{
  max-height: 60vh;
}
.image-container{
  display: flex;
  justify-content: space-evenly;
  padding: 30px;
}
h1{
  text-align: center;
  font-size: 65px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 75%;
  display: flex;
  justify-content: center;
}
.techstack{
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding-top: 30px;
  padding-left: 13%;
}
p{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 52px;
  color: #E0D8CC;
  padding-left: 13%;
  padding-right: 10%;
  margin-top: 0;
}
</style>