<template>
    <head>
      <title>STRIDeS Website</title>
    </head>
    <h1>STRIDeS Website</h1>
  <hr class="border">
    <div>
        <carousel :per-page="1">
            <carousel-slide v-for="(image, index) in images" :key="index">
                <img :src="image" alt="Carousel Image">
            </carousel-slide>
        </carousel>
    </div>
    <div class="img-cont">
  <div class="image-container">
    <img :src="require('/src/assets/STRIDeS-Landing-Desktop.png')">
    <img :src="require('/src/assets/STRIDeS-Landing-Mobile.png')">
      <img :src="require('/src/assets/stridesheader.png')">
      <img :src="require('/src/assets/stridesfooter.png')">
  </div>
    </div>
  <hr class="border">
  <div class="tech-container">
    <h2 class="techstack">Tech Stack</h2>
    <hr class="border">
    <p>Wordpress and PHP Storm </p>
  </div>
  <div>
    <h2 class="techstack">Overview</h2>
    <hr class="border">
    <p>
        STRIDeS is a website for teachers and students to access science teaching resources. The site was created for a professor of biology at Ball State. I was responsible for creating the landing page, the header, and the footer using Wordpress.
    </p>
    <p>
        The landing page was created as a placeholder until the site was completed. I was only given 48 hours to make the landing page based off of a mockup given to me by the design team. I utilized a Wordpress email plugin so visitors could sign up for site updates. I styled the page for both desktop and mobile. I was able to successfully code the page and have it approved in time.
    </p>
    <p>
        Once the design was completed, the tasks for the website were split up between me and the other developers on the project. I was assigned the header and footer for the site. The header utilizes the Wordpress Nav Menu, so admins of the site can easily change the routes of the site. The header also collapses into a hamburger menu when the site is viewed on mobile. The footer links out to the appropriate sites as well as an email to contact the site admin.
    </p>
  </div>
  <div class="arrow-container">
    <router-link to="/Projects">
      <img :src="require('/src/assets/Arrow2.png')" >
    </router-link>
    <h1 class="back">Back to projects</h1>
  </div>
</template>

<script>
import Carousel from 'vue-carousel';

export default {
    title: 'STRIDes Website Project',
    name: "STRIDeS",
    components: {
        Carousel,
    },
    data() {
        return {
            images: [
                '/stridesfooter.png',
                '/stridesheader.png',
                '/STRIDeS-Landing-Desktop.png',
                // Add more images here
            ]
        };
    }
}
</script>

<style scoped>
.arrow-container{
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}
.back{
  text-decoration: none;
  color: #E0D8CC;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
}
img{
  max-height: 30vh;
}
.image-container{
    display: inline-grid;
    grid-template-columns: repeat(2, [col-start] 1fr);
    grid-gap: 10px;
}
h1{
  text-align: center;
  font-size: 65px;
  margin-bottom: 0;
  font-family: 'Mate SC';
  font-style: normal;
  font-weight: 400;
  color: #E0D8CC;
}
.border{
  border-bottom: #E0D8CC solid 2px;
  width: 75%;
  display: flex;
  justify-content: center;
}
.techstack{
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding-top: 30px;
  padding-left: 13%;
}
p{
  font-family: 'Anek Bangla';
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 52px;
  color: #E0D8CC;
  padding-left: 13%;
  padding-right: 10%;
  margin-top: 0;
}
.image-container img{
    height: auto;
    width: 300px;
}
.img-cont{
    display: flex;
    justify-content: center;
}
</style>