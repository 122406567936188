<template>
    <head>
      <title>Resume</title>
    </head>
    <div class="resume-container">
    <iframe class="resume" src="DJNelson.pdf" height="100%" width="100%"></iframe>
  </div>
</template>



<script >
export default {
    title: 'Résumé',
    name: "ResumePage",

}
</script>

<style scoped>
.resume{
  width: 90vh;
  height: 100vh;
}
.resume-container{
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;

}
</style>